<template>
  <div class="my-orders-container">
    <div class="my-orders-content font-menu-small">
      <div class="my-orders-item">
        <div class="order-status">
          <div class="order-date font-menu-medium">
            {{ ordersInfo.createDate }}
          </div>
          <div
            class="id-and-status"
            :class="{ cancelled: ordersInfo.status === 'CANCELLED' }"
          >
            <div class="id">ID{{ ordersInfo.id }}</div>
            <div>|</div>
            <div class="status">
              {{ ordersInfo.status }}
            </div>
          </div>
        </div>
        <div class="order-detail font-menu-small">
          <div class="order-item">
            <div class="name">COURSE</div>
            <div class="value">
              {{ ordersInfo.courseNum}} Classes Package
            </div>
          </div>
          <div class="order-item">
            <div class="name">TOTAL PRICE</div>
            <div class="value" v-if="currency === 0">
              <!-- <span>￥ </span>{{ ordersInfo.totalPrice |  }} -->
              <span>￥ </span>{{ ordersInfo.totalPrice }}
            </div>

            <div class="value" v-else-if="currency === 1">
              {{ ordersInfo.totalPrice }}<span> €</span>
            </div>

            <div class="value" v-else>
              <span>$ </span>{{ ordersInfo.totalPrice }}
            </div>
          </div>
          <div class="order-item">
            <div class="name">UNIT PRICE</div>
            <div class="value" v-if="currency === 0">
              <span>￥ </span>{{ ordersInfo.unitPrice }}
            </div>

            <div class="value" v-else-if="currency === 1">
              {{ ordersInfo.unitPrice }}<span> €</span>
            </div>

            <div class="value" v-else>
              <span>$ </span>{{ ordersInfo.unitPrice }}
            </div>
          </div>
          <div class="order-item">
            <div class="name">START DATE</div>
            <div class="value">{{ ordersInfo.startDate }}</div>
          </div>
          <div class="order-item">
            <div class="name">END DATE</div>
            <div class="value">{{ ordersInfo.endDate }}</div>
          </div>
          <div class="order-item">
            <div class="name">INVOICE</div>
            <div class="value">
              <img src="@/assets/img/19-Dashboard/icon-invoice.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ordersInfo: {
      type: Object,
      default: () => {},
      require: true,
    },
    currency: {
      type: Number,
      default: 0,
      require: true,
    },
  },
  data() {
    return {
      orderNames: [
        "COURSE",
        "TOTAL PRICE",
        "UNIT PRICE",
        "START DATE",
        "END DATE",
        "INVOICE",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.my-orders-container {
  width: 100%;
  .my-orders-content {
    cursor: default;
    margin-bottom: 5%;
    .my-orders-item {
      padding: 2% 4%;
      background: #fff;
      margin-bottom: 2%;
      margin-right: 2%;
      border-radius: 10px;
      .order-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #224e96;
        border-bottom: 1px solid #cfcecb;
        padding-bottom: 2%;
        margin-bottom: 2%;
        font-weight: 600;
        .order-date {
        }
        .id-and-status {
          width: 18%;
          display: flex;
          justify-content: space-between;
        }
        .cancelled {
          width: 25%;
          .status {
            color: #cd4c3f;
          }
        }
      }
      .order-detail {
        display: flex;
        justify-content: flex-end;
        .order-item {
          flex: 1;
        }
        .value {
          color: #224e96;
          img {
            max-width: 21px;
          }
        }
        .name {
          color: #9e9e9f;
          margin-bottom: 3%;
        }
        :first-child {
          flex: 2;
          .value {
            color: #cd4c3f;
          }
        }
      }
    }
  }
}
</style>
