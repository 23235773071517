<template>
  <div class="my-orders-container">
    <PageLayout>
      <template #page-name>
        <div class="nav-title font-menu-large">
          <div
            class="nav-title-item"
            v-for="item in navList"
            :key="item.id"
            :class="{ active: item.id === currentPage }"
            @click="changePage(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
      </template>

      <template #page-content>
        <div class="content">
          <div class="currency-area">
            <div class="currency font-menu-small">
              <div
                class="currency-item"
                :class="{ active: currencyNum === index }"
                v-for="(item, index) in currencyList"
                :key="item.id"
                @click="changeCurrency(index)"
              >
                {{ item.value }}
              </div>
            </div>
          </div>
          <div class="my-order-box">
            <template v-for="(item, index) in myOrdersList">
              <Orders :ordersInfo="item" :currency="currencyNum" :key="index" />
            </template>
          </div>
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import { getMyOrdersInfo } from "@/assets/js/axios/kidsApi";
import PageLayout from "@/components/Page/Layout/PageLayout.vue";
import Orders from "@/components/Page/Orders";
export default {
  name: "MyOrders",

  components: {
    PageLayout,
    Orders,
  },
  data() {
    return {
      currentPage: 1,
      navList: [
        {
          title: "My orders",
          id: 1,
        },
      ],
      myOrdersList: [],
      currencyList: [
        {
          value: "￥ CNY",
          id: 0,
        },
        {
          value: "€ EUR",
          id: 1,
        },
        {
          value: "$ USD",
          id: 2,
        },
      ],
      currencyNum: 0,
    };
  },
  async created() {
    this.myOrdersList = [];
    const result = await getMyOrdersInfo();
    result.data.allContractVOList.forEach((item) => {
      if (
        item.status === 2 ||
        item.status === 4 ||
        item.status === 8 ||
        item.status === 17
      ) {
        this.myOrdersList.push(item);
      }
    });
    this.myOrdersList = this.myOrdersList.map((item) => {
      return {
        courseNum: item.quantity,
        id: item.id,
        createDate: "2021-12-21",
        totalPrice: item.price,
        unitPrice: item.price / item.quantity,
        startDate: String(item.startDate).split("T")[0],
        endDate: String(item.endDate).split("T")[0],
        status: this.hansleOrderStatus(item.status),
      };
    });
  },
  methods: {
    hansleOrderStatus(value) {
      let statusInfo = "";
      switch (value) {
        case 2:
          statusInfo = "AwaitingPayment";
          break;
        case 4:
          statusInfo = "Active";
          break;
        case 8:
          statusInfo = "Finished";
          break;
        case 17:
          statusInfo = "Pending"; //Confirmation
          break;
      }
      return statusInfo;
    },
    changePage(index) {
      this.currentPage = index;
    },
    changeCurrency(index) {
      this.currencyNum = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-orders-container {
  width: 100%;
  height: 100%;
  .active {
    color: #224e96;
  }

  .nav-title {
    display: flex;

    .nav-title-item {
      cursor: default;
      font-weight: 600;
      &:not(.active) {
        cursor: pointer;
      }
      // margin-right: 5%;
      a {
        color: #9e9e9f;
      }
    }
  }

  .content {
    width: 100%;
    // height: 100%;
    height: 70vh;
    background: #fafafa;
    font-weight: 600;
    .currency-area {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .currency {
        width: 25%;
        min-width: 250px;
        // float: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        // padding: 1% 2%;
        margin-bottom: 2%;
        border-radius: 30px;
        color: #224e96;

        .currency-item {
          text-align: center;
          background: #fff;
          border-radius: 30px;
          padding: 4% 8%;
          cursor: pointer;
          font-weight: 600;
        }
        .active {
          background: #cd4c3f;
          color: #fff;
        }
      }
    }
    .my-order-box {
      padding-bottom: 2%;
    }
  }
  /deep/ .page-content {
    background: #fafafa;
    padding: 0;
    border-radius: 0;
  }
}
</style>
